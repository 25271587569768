<nav id="navigation" class="navigation site-nav">
  <div class="nav-container">

    <div class="outer outer-left opacity-10">
      <div class="inner inner-left"></div>
    </div>

    <!-- START NAVIGATION MENU ITEMS -->
    <ul class="onstart animated" data-animation="fadeInDown" data-animation-delay="800">
      <li><a class="active" data-slide="1">Home</a></li>
      <li><a data-slide="2">Precios</a></li>
      <li><a data-slide="3">Nosotros</a></li>
      <li><a data-slide="4">Contacto</a></li>
      <li><a data-slide="5">Servicios</a></li>
    </ul><!-- END NAVIGATION MENU ITEMS -->

    <div class="nav-bg opacity-10"></div>

    <div class="outer outer-right opacity-10">
      <div class="inner inner-right"></div>
    </div>

  </div>
</nav>
