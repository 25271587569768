import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  sendContactMessage(message:any) {
    return this.firestore.collection('/mail').add(message);
  }
}
