<!-- START SECTION -->
<section class="home section fullscreen site-main">
  <div class="section-container">
    <div class="table-container">
      <div class="table-content">
        <!-- START MY CAROUSEL -->
        <div class="bt-carousel">

          <!-- HOME SECTION -->
          <div class="slide-item active" data-slide-id="1">
            <!-- START CONTAINER -->
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <!-- START SLIDE CONTENT-->
                  <div class="slide-content">
                    <div id="clock" class="onstart animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="600" data-animation-out-delay="1000"></div>
                    <h3 class="color-white onstart animated no-top-margin" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="800" data-animation-out-delay="800">Línea de Taxi Aeropuerto Express, tu mejor opción al viajar a nivel nacional y trasladarse en la ciudad de Barquisimeto.</h3>
                    <a href="#" class="default-button bt-white go-slide onstart animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="1000" data-animation-out-delay="600" data-slide="2">Precios</a>
                    <a href="#" class="default-button bt-transparent go-slide onstart animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="1000" data-animation-out-delay="600" data-slide="3">Más info <i class="fa fa-angle-down"></i></a>
                    <br><br>
                    <a href="tel:+584243770474" class="default-button bt-transparent go-slide onstart animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="1000" data-animation-out-delay="600"><i class="fa fa-phone"></i> Llamanos al 0424 377 0474 </a>

                  </div><!-- END SLIDE CONTENT-->
                </div>
              </div><!-- END ROW -->
            </div><!-- END CONTAINER -->
          </div>

          <!-- SUBSCRIBE SECTION -->
          <div class="slide-item" data-slide-id="2">
            <!-- START CONTAINER -->
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <!-- START SECTION HEADER -->
                  <div class="section-header color-white">
                    <!-- START TITLE -->
                    <h2 class="section-title animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-out-delay="1000">Pregunta por nuestros precios y servicios</h2><!-- END TITLE -->
                    <div class="line-separate line-white line-center animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="200" data-animation-out-delay="1200"><span></span></div>
                  </div><!-- END SECTION HEADER -->
                  <!-- START SLIDE CONTENT-->
                  <div class="slide-content">
                    <p class="animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="200" data-animation-out-delay="800">Para tener una lista de nuestros precios y servicios, dejanos tu Email (correo electrónico) y con gusto te haremos llegar la información actualizada.</p>
                    <!-- Subscribe Form -->
                    <div class="form animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="400" data-animation-out-delay="600">
                      <div class="form-container">
                        <div class="page-section" id="signup">
                          <form class="subscription-form mailchimp" method="post" id="mc-form">
                            <input type="email" id="email" class="border-radius-22 no-bottom-margin" name="email" placeholder="Ingresa tu correo" />
                            <button type="submit" id="subscribebtn" class="border-button no-bottom-margin">Enviar</button>
                          </form>
                          <p class="error-message"></p>
                          <p class="success-message"></p>
                        </div><!--end page section -->
                      </div><!-- end form container -->
                    </div><!-- end Subscribe Form -->
                  </div><!-- END SLIDE CONTENT-->
                </div>
              </div><!-- END ROW -->
            </div><!-- END CONTAINER -->
          </div>

          <!-- ABOUT SECTION -->
          <div class="slide-item" data-slide-id="3">
            <!-- START CONTAINER -->
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <!-- START SECTION HEADER -->
                  <div class="section-header">
                    <!-- START TITLE -->
                    <h2 class="section-title animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-out-delay="1000">Nosotros</h2><!-- END TITLE -->
                    <div class="line-separate line-white line-center animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="200" data-animation-out-delay="800"><span></span></div>
                  </div><!-- END SECTION HEADER -->
                  <!-- START SLIDE CONTENT-->
                  <div class="slide-content animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="400" data-animation-out-delay="600">
                    <p>Somos una Sociedad Civil constituida por mas de 70 unidades, prestando un <strong>Servicio Ejecutivo</strong> a toda la región por mas de 20 años. Nos caracteriza la responsabilidad, puntualidad y compromiso con la satisfacción y confort de nuestros clientes.</p>
                    <div class="row">
                      <div class="col-md-6">
                        <!-- START FEATURES-LIST -->
                        <div class="features-list">

                          <!-- FEATURE -->
                          <div class="feature-item">
                            <div class="feature-icon">
                              <i class="ion-ios-speedometer-outline"></i>
                            </div>
                            <div class="feature-text">
                              <h3>Unidades A1</h3>
                              <p>Contamos con unidades 100% operativas y con todas las caracteristicas de un Servicio Ejecutivo.</p>
                            </div>
                          </div>

                          <!-- FEATURE -->
                          <div class="feature-item">
                            <div class="feature-icon">
                              <i class="ion-ios-heart-outline"></i>
                            </div>
                            <div class="feature-text">
                              <h3>Te encantará</h3>
                              <p>Musica a gusto, periodicos y revistas para informarse, AA, maleteras espaciosas.</p>
                            </div>
                          </div>

                        </div><!-- END FEATURES-LIST -->
                      </div>
                      <div class="col-md-6">
                        <!-- START FEATURES-LIST -->
                        <div class="features-list">

                          <!-- FEATURE -->
                          <div class="feature-item">
                            <div class="feature-icon">
                              <i class="ion-ios-alarm-outline"></i>
                            </div>
                            <div class="feature-text">
                              <h3>Llegarás a tiempo</h3>
                              <p>Somos conductores comprometidos con nuestra labor y la puntualidad es nuestra premisa.</p>
                            </div>
                          </div>

                          <!-- FEATURE -->
                          <div class="feature-item">
                            <div class="feature-icon">
                              <i class="ion-ios-videocam-outline"></i>
                            </div>
                            <div class="feature-text">
                              <h3>Seguridad</h3>
                              <p>Contamos con camaras en todas las unidades, asi como radios para mantenernos en contacto en cada Km, siempre sabran donde estamos!.</p>
                            </div>
                          </div>

                        </div><!-- END FEATURES-LIST -->
                      </div>
                    </div>
                  </div><!-- END SLIDE CONTENT-->
                </div>
              </div><!-- END ROW -->
            </div><!-- END CONTAINER -->
          </div>

          <!-- CONTACT SECTION -->
          <div class="slide-item" data-slide-id="4">
            <!-- START CONTAINER -->
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <!-- START SECTION HEADER -->
                  <div class="section-header">
                    <!-- START TITLE -->
                    <h2 class="section-title animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-out-delay="1000">Tienes alguna pregunta ?</h2><!-- END TITLE -->
                    <div class="line-separate line-white line-center animated" data-animation-out="fadeOutDown" data-animation="fadeInUp" data-animation-delay="200" data-animation-out-delay="800"><span></span></div>
                  </div><!-- END SECTION HEADER -->
                  <!-- START SLIDE CONTENT-->
                  <div class="slide-content">
                    <p class="animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="400" data-animation-out-delay="600">Con gusto responderemos tu pregunta, envianos tus datos junto con tu pregunta y te contactaremos lo más pronto posible. Recuerda <strong>Línea de Taxi Aeropuerto Express</strong> esta pra servirles</p>
                    <!-- START CONTACT FORM -->
                    <!-- <form method="post" action="send-mail.php" class="contact-form"> -->
                      <form [formGroup]="formContact" (ngSubmit)="onSubmit()">
                        <div class="row">
                          <div class="col-md-4 animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="600" data-animation-out-delay="400">
                            <input type="text" name="name" placeholder="Nombre" formControlName="name" class="required">
                          </div><!-- END COLUMN 4 -->
                          <div class="col-md-4 animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="700" data-animation-out-delay="300">
                            <input type="email" name="email" placeholder="Dirección Email" formControlName="email" class="contact-form-email required">
                          </div><!-- END COLUMN 4 -->
                          <div class="col-md-4 animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="800" data-animation-out-delay="200">
                            <input type="text" name="subject" placeholder="Asunto" formControlName="subject" class="contact-form-subject">
                          </div><!-- END COLUMN 4 -->
                        </div><!-- END ROW -->
                        <textarea formControlName="message" name="message" placeholder="Mensaje" class="required animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="900" rows="7"></textarea>
                        <div class="response-message"></div>
                        <button class="border-button animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="1000" type="submit" id="submit" name="submit" [disabled]="formContact.invalid">Enviar mensaje</button>
                        <!--</form> END CONTACT FORM -->
                      </form>
                  </div><!-- END SLIDE CONTENT-->
                </div>
              </div><!-- END ROW -->
            </div><!-- END CONTAINER -->
          </div>

          <!-- FEATURES SECTION -->
          <div class="slide-item" data-slide-id="5">
            <!-- START CONTAINER -->
            <div class="container">
              <div class="row">
                <div class="col-md-10 col-md-offset-1">
                  <!-- START SECTION HEADER -->
                  <div class="tour-header">
                    <!-- START TITLE -->
                    <h2 class="section-title animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-out-delay="1000">Nuestros Servicios</h2><!-- END TITLE -->
                    <div class="line-separate line-white line-center animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="200" data-animation-out-delay="800"><span></span></div>
                  </div><!-- END SECTION HEADER -->
                  <!-- START SLIDE CONTENT-->
                  <div class="slide-content">
                    <p class="animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="400" data-animation-out-delay="600">Estos solo son algunos de los servicios que prestamos.</p>

                    <!-- SERVICE -->
                    <div class="col-md-4 service animated" data-animation="fadeInLeft" data-animation-out="fadeOutLeft" data-animation-delay="800" data-animation-out-delay="600">
                      <div class="service-icon">
                        <i class="ion-ios-book-outline"></i>
                      </div>
                      <h3>Taxi En Toda La Región</h3>
                      <p>Barquisimeto, Cabudare, Carora, Quibor, Yaritagua, Acarigua, son algunas de las ciudades en las que a diario hacen traslados nuestras unidades.</p>
                    </div>

                    <!-- SERVICE -->
                    <div class="col-md-4 service animated" data-animation="fadeInUp" data-animation-out="fadeOutDown" data-animation-delay="600" data-animation-out-delay="600">
                      <div class="service-icon">
                        <i class="ion-ios-speedometer-outline"></i>
                      </div>
                      <h3>Viajes A Nivel Nacional</h3>
                      <p>Realizamos viajes hacia todo el territorio nacional Venezuela, ya que contamos con unidades aptas para dicha labor y confort del cliente durante el mismo.</p>
                    </div>

                    <!-- SERVICE -->
                    <div class="col-md-4 service animated" data-animation="fadeInRight" data-animation-out="fadeOutRight" data-animation-delay="800" data-animation-out-delay="600">
                      <div class="service-icon">
                        <i class="ion-plane"></i>
                      </div>
                      <h3>Traslado Al Aeropuerto</h3>
                      <p>Agenda tu traslado al Aeropuerto internacional Jacinto Lara en vuelos madrugadores, nuestra puntualidad te hara llegar a tiempo.</p>
                    </div>

                  </div><!-- END SLIDE CONTENT-->
                </div>
              </div><!-- END ROW -->
            </div><!-- END CONTAINER -->
          </div>

        </div><!-- END BT-CAROUSEL -->
      </div>
    </div>
  </div>
  <footer class="site-footer onstart animated" data-animation="fadeInUp" data-animation-delay="800">

  </footer>
</section>
