import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  formContact: FormGroup

  constructor(
    private fb: FormBuilder,
    private infoService: InfoService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.formContact = this.fb.group({
      name:new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      html: new FormControl('')
    });
  }

  onSubmit() {
    if(this.formContact.valid) {
      const html = `
      <p>Este es un correo desde la página web <strong>Línea de Taxi Aeropuerto Express</strong>. La siguiente persona desea contactarse con ud.</p>
      <div>De: ${this.formContact.controls['name'].value}</div>
      <div>Email: ${this.formContact.controls['email'].value}</div>
      <div>Mensaje: ${this.formContact.controls['message'].value}</div>
      `;
      this.formContact.controls['html'].patchValue(html);
      const formValue = {
        to:['jajeantp@gmail.com','jjeantonollarves@gmail.com'],
        message:{
          subject:this.formContact.controls['subject'].value,
          text:'Mail de contacto desde Página Web',
          html:html
        },
        body:html
      }
      this.infoService.sendContactMessage(formValue).then(resp => {
        console.log('mensaje enviado');
        this.snackBar.open('Mensaje Enviado Exitosamente', 'Contacto', {duration:4000});
        this.formContact.reset();
      });
    }
  }



}
